<template>
    <div class="text-loader">
        <div class="lds-ripple"><div></div><div></div></div>
        <p>Anda akan segera diarahkan ke halaman yang terkait</p>
    </div>
</template>

<script setup>
    import { setPageData } from '@/functions/general';
    import { useShortenerStore } from '@/stores/shortener';
    import { onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    onMounted(() => {
        setPageData({
            title: 'RSIA Melinda Bandung - URL Shortener',
            description: 'Ini adalah URL Shortener RSIA Melinda Bandung'
        });
    });
    useShortenerStore().getUrl(useRoute().params.token);
    scrollTo(0, 0);
</script>

<style scoped>
    .text-loader{
        width: 100%;
        min-height: 500px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--primary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>