<template>
    <section>
        <div class="top-bg"></div>
    </section>
</template>

<script>
    // 
</script>

<style scoped>
    section{
        position: relative;
        width: 100%;
        z-index: -1;
    }
    .top-bg{
        position: absolute;
        top: 0;
        left: 0;
        background: var(--navy);
        width: 100%;
        height: 370px;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1200px){
        .top-bg{
            /* height: 266px; */
        }
    }
    @media only screen and (max-width: 750px){
        .top-bg{
            height: 0;
            border-bottom: none;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: none;
            /* display: none; */
        }
    }
</style>