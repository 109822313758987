<template>
    <div class="home-header">
        <div class="hc-control">
            <div class="hc-button hcb-left">{{ '<' }}</div>
            <div class="hc-button hcb-right">{{ '>' }}</div>
        </div>
        <div class="hh-carousel carousel hhc-smooth">
            <div class="hc-part">
                <div class="hcp-inner">
                    <div class="hh-part hh-left">
                        <div class="hh-left-inner">
                            <span class="hli-title">Melinda Hospital</span>
                            <p>Dedikasi kami selama 20 Tahun di dunia kesehatan merupakan bukti nyata keseriusan dan komitmen yang kami miliki. Kami selalu berupaya untuk tumbuh dan berkembang sesuai dengan kemajuan zaman dan teknologi guna meningkatkan pelayanan kesehatan yang lebih baik dengan didukung oleh dokter spesialis yang ahli di bidangnya serta para staff yang telah terbukti terlatih dan berdedikasi.</p>
                            <!-- <div class="more button-main">
                                <router-link to="/about">Readmore</router-link>
                            </div> -->
                            <div class="complete-description">
                                <div class="cd-button">
                                    <span @click="mainDescription();">Selengkapnya &rarr;</span>
                                </div>
                                <div class="cd-content" id="cd-content">
                                    <div class="cd-content-close" @click="mainDescription();">Tutup</div>
                                    <div class="cd-main-content">
                                        <div class="cmc-content" id="cmc-content">
                                            <h1><strong>{{ site.title }}</strong></h1>
                                            <p>Dedikasi kami selama 20 Tahun di dunia kesehatan merupakan bukti nyata keseriusan dan komitmen yang kami miliki. Kami selalu berupaya untuk tumbuh dan berkembang sesuai dengan kemajuan zaman dan teknologi guna meningkatkan pelayanan kesehatan yang lebih baik dengan didukung oleh dokter spesialis yang ahli di bidangnya serta para staff yang telah terbukti terlatih dan berdedikasi.</p>
                                            <h2>Tentang Melinda Hospital</h2>
                                            <p>Melinda Hospital adalah jaringan rumah sakit terkemuka di Kota Bandung, yang telah melayani masyarakat dengan dedikasi tinggi selama lebih dari dua dekade.</p>
                                            <h3>Layanan</h3>
                                            <p>Melinda Hospital telah melayani masyarakat dengan dedikasi tinggi selama lebih dari dua dekade. Melinda Hospital telah membuktikan komitmen untuk memberikan perawatan kesehatan terbaik kepada semua pasien.</p>
                                            <p>Komitmen Melinda Hospital terhadap kualitas pelayanan tidak diragukan lagi. Setiap aspek dari operasional rumah sakit dirancang untuk memastikan bahwa pasien menerima perawatan yang terbaik dan paling mutakhir.</p>
                                            <p>Dengan pelayanan yang konsisten dan berkualitas, Melinda Hospital telah membangun reputasi yang kuat di kalangan masyarakat Kota Bandung. Kepercayaan yang diberikan oleh pasien selama bertahun-tahun merupakan bukti nyata dari kualitas layanan yang diberikan.</p>
                                            <h3>Lokasi</h3>
                                            <p>Unit-unit Rumah Sakit Melinda Hospital terletak di lokasi-lokasi yang strategis di Kota Bandung, memudahkan akses bagi masyarakat sekitar. Keberadaannya di kota besar ini memastikan bahwa banyak orang dapat dengan mudah mendapatkan layanan kesehatan yang mereka butuhkan.</p>
                                            <h2>Unit-unit Rumah Sakit di Bawah Melinda Hospital</h2>
                                            <p>Saat ini, Melinda Hospital memiliki tiga unit rumah sakit, yaitu RSIA Melinda, RS Melinda 2, dan Melinda Cardio Vascular Center. Masing-masing unit memiliki spesialisasi dan layanan yang berbeda untuk memenuhi kebutuhan kesehatan masyarakat.</p>
                                            <h3>RSIA Melinda</h3>
                                            <p>RSIA Melinda adalah Rumah Sakit Ibu dan Anak. Rumah sakit ini didedikasikan untuk memberikan pelayanan kesehatan terbaik bagi ibu dan anak, mulai dari layanan kehamilan, persalinan, hingga perawatan kesehatan anak. Fasilitas dan tim medis di RSIA Melinda berfokus pada kesehatan ibu dan anak dengan sentuhan kasih dan perhatian khusus.</p>
                                            <h3>RS Melinda 2</h3>
                                            <p>RS Melinda 2 adalah Rumah Sakit Umum yang menyediakan berbagai layanan kesehatan untuk masyarakat umum. Rumah sakit ini menawarkan berbagai spesialisasi medis termasuk bedah, penyakit dalam, pediatrik, dan lainnya. RS Melinda 2 dilengkapi dengan teknologi medis terkini dan tim dokter spesialis yang berpengalaman untuk menangani berbagai kondisi kesehatan.</p>
                                            <h3>Melinda Cardio Vascular Center</h3>
                                            <p>Melinda Cardio Vascular Center adalah Rumah Sakit Khusus Jantung dan Pembuluh Darah. Rumah sakit ini didedikasikan untuk perawatan kesehatan jantung dan pembuluh darah. Dengan fasilitas canggih dan tim ahli jantung yang berpengalaman, Melinda Cardio Vascular Center menyediakan layanan diagnostik, perawatan, dan rehabilitasi jantung yang komprehensif.</p>
                                            <h2>Sejarah Perjalanan Selama 20 Tahun</h2>
                                            <p>Dedikasi kami selama 20 tahun di dunia kesehatan merupakan bukti nyata keseriusan dan komitmen yang kami miliki. Melinda Hospital telah menjadi simbol pelayanan kesehatan yang berkualitas dan terpercaya di Kota Bandung. Selama dua dekade ini, kami terus berusaha memberikan yang terbaik untuk setiap pasien yang mempercayakan kesehatannya kepada kami.</p>
                                            <h3>Perkembangan dari Satu Unit Rumah Sakit</h3>
                                            <p>Melinda Hospital telah melalui berbagai tahap perkembangan sejak awal berdirinya. Berawal dari satu unit Rumah Sakit Ibu dan Anak (RSIA Melinda), kami menghadapi berbagai tantangan dan peluang yang membawa kami ke posisi saat ini. Dalam perjalanan kami, fokus utama selalu pada peningkatan kualitas layanan dan penambahan fasilitas untuk memenuhi kebutuhan kesehatan masyarakat yang semakin kompleks.</p>
                                            <h3>Jaringan Rumah Sakit</h3>
                                            <p>Saat ini, kami telah berkembang menjadi jaringan rumah sakit terkemuka di Kota Bandung. Melinda Hospital kini terdiri dari tiga unit utama yang masing-masing memiliki spesialisasi dan layanan yang berbeda. Selain RSIA Melinda, kami memiliki RS Melinda 2 yang berfungsi sebagai rumah sakit umum, dan Melinda Cardio Vascular Center yang fokus pada pelayanan kesehatan jantung. Keberagaman unit ini memungkinkan kami untuk menyediakan layanan kesehatan yang lebih komprehensif dan holistik kepada masyarakat.</p>
                                            <p>Melinda Hospital telah melalui berbagai tahap perkembangan sejak awal berdirinya. Berawal dari 1 unit Rumah Sakit (RSIA Melinda), kami telah berkembang menjadi jaringan rumah sakit terkemuka di Kota Bandung, dengan berbagai unit spesialis yang lengkap dan modern.</p>
                                            <h2>Komitmen dan Dedikasi dalam Pelayanan</h2>
                                            <p>Kami selalu berupaya untuk tumbuh dan berkembang sesuai dengan kemajuan zaman dan teknologi guna meningkatkan pelayanan kesehatan yang lebih baik. Komitmen ini terlihat dalam setiap aspek operasional dan layanan yang kami berikan.</p>
                                            <p>Kami berkomitmen untuk terus meningkatkan kualitas pelayanan kesehatan. Komitmen ini tercermin dalam setiap aspek operasional kami, termasuk pelatihan berkelanjutan bagi staf medis, pengembangan prosedur standar yang ketat, dan peningkatan fasilitas medis.</p>
                                            <h2>Teknologi Medis Terkini</h2>
                                            <p>Melinda Hospital selalu mengikuti perkembangan teknologi medis terbaru. Kami mengadopsi peralatan medis canggih dan teknik terkini untuk memastikan diagnosis yang akurat dan perawatan yang efektif.</p>
                                            <h2>Tim Dokter Spesialis yang Ahli di Bidangnya</h2>
                                            <p>Kami didukung oleh dokter spesialis yang ahli di bidangnya, memastikan bahwa setiap pasien mendapatkan perawatan medis terbaik sesuai dengan kondisi mereka. Setiap dokter di Melinda Hospital memiliki spesialisasi khusus dan pengalaman mendalam dalam menangani berbagai kondisi medis, mulai dari penyakit umum hingga kasus yang sangat kompleks.</p>
                                            <h3>Pengalaman Luas</h3>
                                            <p>Para dokter kami memiliki pengalaman luas dalam dunia medis, yang memungkinkan mereka untuk memberikan diagnosis yang akurat dan penanganan yang efektif. Dengan bertahun-tahun pengalaman di berbagai bidang medis, mereka mampu menangani berbagai situasi darurat dan kondisi medis yang memerlukan keahlian khusus. Pengalaman ini juga membantu mereka dalam merancang rencana perawatan yang paling sesuai untuk setiap pasien.</p>
                                            <h3>Pelatihan Berkelanjutan</h3>
                                            <p>Di Melinda Hospital, kami sangat memahami pentingnya pembaruan pengetahuan dan keterampilan dalam dunia medis yang terus berkembang. Oleh karena itu, para dokter kami secara rutin mengikuti pelatihan berkelanjutan dan seminar medis baik di tingkat nasional maupun internasional. Ini memastikan bahwa mereka selalu up-to-date dengan perkembangan terbaru dalam teknologi medis, metode pengobatan, dan penelitian klinis.</p>
                                            <h3>Sertifikasi dan Akreditasi</h3>
                                            <p>Para dokter spesialis kami memiliki berbagai sertifikasi dan akreditasi yang diakui secara nasional dan internasional. Sertifikasi ini bukan hanya mencerminkan keahlian mereka, tetapi juga komitmen mereka terhadap standar tertinggi dalam praktik medis. Melalui proses sertifikasi yang ketat, kami memastikan bahwa dokter kami memiliki kompetensi dan integritas profesional yang luar biasa.</p>
                                            <h3>Kolaborasi Multidisiplin</h3>
                                            <p>Untuk memberikan perawatan yang holistik dan komprehensif, dokter-dokter spesialis di Melinda Hospital sering berkolaborasi dalam tim multidisiplin. Ini berarti bahwa pasien mendapatkan manfaat dari berbagai perspektif medis dan keahlian yang berbeda, yang sangat penting dalam penanganan kondisi kesehatan yang kompleks. Kolaborasi ini juga memastikan bahwa semua aspek kesehatan pasien diperhatikan, sehingga mereka mendapatkan perawatan yang paling komprehensif dan efektif.</p>
                                            <h3>Fokus pada Pasien</h3>
                                            <p>Selain keahlian medis, para dokter kami juga dikenal karena pendekatan mereka yang berpusat pada pasien. Mereka tidak hanya fokus pada penyakit, tetapi juga pada kebutuhan emosional dan psikologis pasien. Dengan mendengarkan keluhan dan harapan pasien, dokter kami mampu memberikan perawatan yang tidak hanya efektif secara medis tetapi juga memberikan kenyamanan dan dukungan emosional.</p>
                                            <h3>Penelitian dan Inovasi</h3>
                                            <p>Dokter-dokter di Melinda Hospital juga aktif terlibat dalam penelitian medis dan inovasi. Dengan berpartisipasi dalam berbagai proyek penelitian, mereka berkontribusi pada pengembangan ilmu pengetahuan medis dan peningkatan praktik klinis. Inovasi ini sering kali diterapkan dalam perawatan sehari-hari, membawa manfaat langsung bagi pasien dengan pendekatan pengobatan yang lebih baru dan efektif.</p>
                                            <p>Dengan dukungan dari dokter-dokter spesialis yang ahli, berpengalaman, dan terus mengembangkan diri, Melinda Hospital berkomitmen untuk memberikan layanan kesehatan yang terbaik dan terdepan bagi setiap pasien.</p>
                                            <h2>Pelatihan dan Profesionalisme Para Staff</h2>
                                            <p>Para staff di Melinda Hospital telah terbukti terlatih dan berdedikasi. Pelatihan berkelanjutan dan pengembangan profesional adalah prioritas kami untuk memastikan bahwa setiap anggota tim dapat memberikan pelayanan terbaik.</p>
                                            <h2>Layanan Kesehatan Berkualitas</h2>
                                            <p>Kami menyediakan berbagai layanan kesehatan berkualitas, dari pemeriksaan rutin hingga perawatan khusus. Layanan kami dirancang untuk memenuhi kebutuhan kesehatan masyarakat dengan pendekatan holistik dan personal.</p>
                                            <h3>Pemeriksaan Rutin</h3>
                                            <p>Pemeriksaan rutin ini meliputi pemeriksaan fisik, tes laboratorium, dan evaluasi kesehatan secara keseluruhan untuk mendeteksi potensi masalah kesehatan sejak dini.</p>
                                            <h3>Perawatan Khusus</h3>
                                            <p>Selain pemeriksaan rutin, kami juga menawarkan perawatan khusus untuk berbagai kondisi medis. Layanan perawatan khusus ini mencakup berbagai bidang spesialisasi seperti kardiologi, ortopedi, onkologi, dan banyak lagi, yang semuanya ditangani oleh dokter spesialis yang ahli di bidangnya.</p>
                                            <h3>Medical Check Up</h3>
                                            <p>Salah satu layanan unggulan kami adalah Medical Check Up. Program ini dirancang untuk memberikan penilaian kesehatan menyeluruh yang mencakup berbagai tes dan evaluasi medis untuk mendeteksi penyakit atau kondisi kesehatan yang mungkin tidak menunjukkan gejala awal. Medical Check Up kami membantu Anda dalam menjaga kesehatan dan mengambil tindakan preventif yang diperlukan.</p>
                                            <h3>Pendekatan Holistik</h3>
                                            <p>Layanan kami dirancang untuk memenuhi kebutuhan kesehatan masyarakat dengan pendekatan holistik. Kami tidak hanya fokus pada pengobatan penyakit, tetapi juga pada pencegahan, rehabilitasi, dan peningkatan kualitas hidup pasien. Pendekatan ini memastikan bahwa setiap aspek kesehatan pasien diperhatikan, termasuk aspek fisik, mental, dan emosional.</p>
                                            <h3>Layanan Darurat dan Rawat Inap</h3>
                                            <p>Kami menyediakan layanan darurat yang siap 24 jam untuk menangani kasus-kasus medis yang membutuhkan penanganan segera. Selain itu, layanan rawat inap kami dirancang untuk memberikan kenyamanan dan perawatan intensif bagi pasien yang memerlukan pengobatan jangka panjang atau pasca-operasi.</p>
                                            <h3>Bandung Urology Center</h3>
                                            <p>Bandung Urology Center di Melinda Hospital menyediakan layanan urologi lengkap yang menangani berbagai kondisi kesehatan terkait saluran kemih dan sistem reproduksi pria. Dengan dukungan teknologi terkini dan dokter spesialis urologi yang berpengalaman, pusat ini menawarkan diagnosis dan perawatan efektif untuk masalah urologi.</p>
                                            <h3>Melinda Sport Center</h3>
                                            <p>Melinda Sport Center menyediakan fasilitas dan layanan untuk kesehatan olahraga, termasuk fisioterapi, rehabilitasi cedera olahraga, dan program kebugaran. Pusat ini dirancang untuk membantu atlet dan individu aktif dalam menjaga kondisi fisik optimal dan mencegah cedera.</p>
                                            <h3>Hydrotherapy</h3>
                                            <p>Layanan hydrotherapy di Melinda Hospital menggunakan terapi air untuk mengobati berbagai kondisi kesehatan dan mempercepat pemulihan. Terapi ini efektif dalam mengurangi nyeri, meningkatkan mobilitas, dan mempercepat proses rehabilitasi.</p>
                                            <h3>Aquabase</h3>
                                            <p>Aquabase adalah fasilitas terapi air yang menyediakan program latihan dan rehabilitasi berbasis air untuk berbagai kondisi kesehatan. Dengan dukungan instruktur terlatih, pasien dapat menjalani terapi yang lembut namun efektif untuk meningkatkan kesehatan dan kebugaran.</p>
                                            <h3>Infant and Kids Therapy Center</h3>
                                            <p>Terapi tumbuh kembang anak motorik adalah suatu pendekatan terapeutik yang bertujuan untuk meningkatkan kemampuan motorik dan perkembangan fisik anak. Infant and Kids Therapy Center adalah layanan yang berpusat pada terapi motorik dan optimalisasi tumbuh kembang bayi high-risk, bayi-bayi post-NICU, dan anak-anak yang mengalami keterlambatan perkembangan baik karena pernah mendapatkan tindakan di NICU maupun gangguan pada sistem motorik akibat gangguan sistem saraf. Layanan Infant and Kids Therapy Center berupaya untuk memberikan pelayanan yang maju kepada pasien, sehingga anak nantinya akan memiliki pertumbuhan dan perkembangan yang baik sesuai dengan usianya. Berikut beberapa layanan Infant and Kids Therapy Center yang berfokus pada anak, yaitu:</p>
                                            <ul>
                                                <li>Infant and Kids Chest Therapy</li>
                                                <li>Infant and Kids Therapeutic Massage</li>
                                                <li>Pediatric Exercise and Movement Therapy in Special Cases</li>
                                                <li>Pediatric Sport and Wellness</li>
                                                <li>NICU Neonatus Touch and Movement Therapy</li>
                                            </ul>
                                            <h2>Fasilitas Kesehatan Lengkap dan Modern</h2>
                                            <p>Melinda Hospital dilengkapi dengan fasilitas kesehatan yang lengkap dan modern. Mulai dari ruang rawat inap yang nyaman, ruang operasi yang dilengkapi dengan teknologi mutakhir, hingga laboratorium medis yang terakreditasi.</p>
                                            <h2>Program Kesehatan Masyarakat dan Tanggung Jawab Sosial</h2>
                                            <p>Sebagai bagian dari tanggung jawab sosial, Melinda Hospital aktif dalam berbagai program kesehatan masyarakat yang bertujuan untuk meningkatkan kesejahteraan dan kualitas hidup masyarakat secara luas. Kami percaya bahwa kesehatan adalah hak setiap individu, oleh karena itu, kami berkomitmen untuk menyediakan akses yang lebih luas terhadap informasi dan layanan kesehatan melalui berbagai inisiatif yang kami jalankan.</p>
                                            <h3>Kampanye Kesehatan</h3>
                                            <p>Melinda Hospital secara rutin menyelenggarakan kampanye kesehatan yang menargetkan berbagai isu kesehatan penting. Kampanye ini mencakup edukasi mengenai pencegahan penyakit, pentingnya gaya hidup sehat, dan penanganan awal terhadap kondisi medis tertentu. Misalnya, kami mengadakan kampanye tahunan mengenai pencegahan kanker dengan menyediakan informasi tentang deteksi dini, pilihan pengobatan, dan pentingnya gaya hidup sehat dalam mencegah penyakit. Selain itu, kampanye kesehatan mental juga menjadi fokus kami, dengan tujuan untuk mengurangi stigma dan meningkatkan pemahaman masyarakat mengenai pentingnya kesehatan mental.</p>
                                            <h3>Pemeriksaan Gratis</h3>
                                            <p>Untuk mendukung kesehatan masyarakat, Melinda Hospital seringkali mengadakan program pemeriksaan kesehatan gratis yang terbuka untuk umum. Program ini mencakup berbagai jenis pemeriksaan seperti tes gula darah, tekanan darah, kolesterol, dan pemeriksaan kesehatan dasar lainnya. Dengan adanya pemeriksaan gratis ini, kami berharap dapat membantu masyarakat dalam mendeteksi dini berbagai kondisi kesehatan dan mendorong mereka untuk mengambil langkah-langkah pencegahan yang diperlukan. Program ini terutama ditargetkan untuk komunitas yang kurang mampu atau yang memiliki akses terbatas terhadap layanan kesehatan.</p>
                                            <h3>Kerjasama dengan Komunitas</h3>
                                            <p>Melinda Hospital juga aktif dalam menjalin kerjasama dengan berbagai komunitas lokal untuk mengembangkan program kesehatan yang relevan dengan kebutuhan spesifik mereka. Kami bekerja sama dengan sekolah-sekolah untuk mengadakan program kesehatan anak dan remaja, dengan organisasi non-profit untuk kampanye kesehatan lingkungan, serta dengan pemerintah daerah untuk inisiatif kesehatan publik. Kerjasama ini memungkinkan kami untuk menjangkau lebih banyak orang dan memberikan dampak yang lebih signifikan dalam meningkatkan kesehatan masyarakat.</p>
                                            <p>Dengan berbagai program kesehatan masyarakat dan inisiatif tanggung jawab sosial ini, Melinda Hospital berusaha untuk berkontribusi secara positif terhadap kesehatan dan kesejahteraan masyarakat. Kami percaya bahwa melalui edukasi, pencegahan, dan kerjasama, kita dapat menciptakan masyarakat yang lebih sehat dan kuat, serta memberikan harapan dan dukungan kepada mereka yang membutuhkan.</p>
                                            <h2>Visi dan Misi Menuju Masa Depan</h2>
                                            <p>Visi dan misi kami adalah terus menjadi rumah sakit terbaik dan terdepan yang memberikan pelayanan kesehatan terbaik dengan mengedepankan inovasi, keahlian, dan kepedulian terhadap pasien. Kami berkomitmen untuk terus berkembang dan memberikan kontribusi positif bagi dunia kesehatan.</p>
                                        </div>
                                        <div class="cmc-index-button" @click="cmcIndex();">
                                            <span>Daftar Isi</span>
                                        </div>
                                        <div class="cmc-index-shadow" id="cmc-index-shadow" @click="cmcIndex();"></div>
                                        <div class="cmc-index" id="cmc-index"></div>
                                    </div>
                                    <!-- <div class="cd-content-inner">
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hh-part hh-right">
                        <div class="img-cover"></div>
                        <img src="../../assets/images/pictures/mhmig.webp" alt="melinda hospital"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { articleIndex, createHtmlIndex } from '@/functions/general';
    import { mainDescription, cmcIndex } from '@/functions/home';
    import { site } from '@/equipments/settings';
    onMounted(() => {
        const indexResult = articleIndex(document.getElementById('cmc-content'));
        const htmlIndex = createHtmlIndex(indexResult);
        const cmcindex = document.getElementById('cmc-index');
        cmcindex.innerHTML = '';
        cmcindex.appendChild(htmlIndex);
        // =====
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.querySelector('.carousel');
        const btleft = document.querySelector('.hcb-left');
        const btright = document.querySelector('.hcb-right');
        // const schildren = slider.children;
        const sparent = slider.parentElement;
        const end = () => {
            isDown = false;
            slider.classList.add('hhc-smooth');
            slider.scrollLeft = Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth;
        }
        const start = (e) => {
            isDown = true;
            slider.classList.remove('hhc-smooth');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }
        const move = (e) => {
            if(!isDown) return;
            // e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX) * 1.9;
            slider.scrollLeft = scrollLeft - dist;
        }
        const goLeft = (e) => {
            e.preventDefault();
            goTop();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) - sparent.scrollWidth;
        }
        const goRight = (e) => {
            e.preventDefault();
            goTop();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) + sparent.scrollWidth;
        }
        const sresize = () => {
            slider.scrollLeft = 0;
        }
        const goTop = () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
        (() => {
            slider.addEventListener('mousedown', start);
            slider.addEventListener('touchstart', start);
            slider.addEventListener('mousemove', move);
            slider.addEventListener('touchmove', move);
            slider.addEventListener('mouseleave', end);
            slider.addEventListener('mouseup', end);
            slider.addEventListener('touchend', end);
            slider.addEventListener('resize', sresize);
            btleft.addEventListener('click', goLeft);
            btright.addEventListener('click', goRight);
        })();
    });
</script>

<style scoped>
    @import url('../../assets/css/articleIndex.css');
    .home-header{
        position: relative;
        width: 100%;
        display: flex;
        height: 67rem;
        overflow: hidden;
        transition: 0.2s;
        user-select: none;
        /* border: 1px solid green; */
        background: var(--primary);
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.2s;
    }
    .hhc-smooth{
        scroll-behavior: smooth;
    }
    .hc-control{
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 5;
        display: none;
        flex-wrap: nowrap;
        min-width: 100px;
        min-height: 50px;
        background: #1b467457;
        transition: 0.2s;
        user-select: none;
    }
    .hc-button{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 2rem;
        cursor: pointer;
    }
    .hc-button:hover{
        color: #eeeeee;
    }
    .hcb-right{
        background: #75C6EF;
    }
    .hc-part{
        display: inline-block;
        min-width: 100%;
        width: 100%;
        height: 100%;
    }
    .hcp-inner{
        position: relative;
        display: flex;
        height: 100%;
        overflow: hidden;
    }
    .hh-part{
        width: 50%;
        height: 100%;
    }
    .hh-left{
        background: var(--navy);
        color: var(--white);
        padding: 8.44rem;
        padding-left: 12.25rem;
        padding-right: 6.69rem;
        padding-bottom: 0;
        transition: 0.2s;
        height: 100%;
        z-index: 5;
    }
    /* .p-1{
        font-weight: 600;
        color: #75C6EF;
    } */
    .hh-left h1{
        font-family: poppins;
        font-size: 3.25rem;
        /* width: 22.56rem; */
        margin: 0;
        /* margin-top: 1.37rem; */
        transition: 0.2s;
    }
    .hli-title{
        font-size: 2rem;
        transition: 0.2s;
    }
    .hh-left-inner p{
        font-family: varela-round;
    }
    /* .p-2{
        margin-top: 1.19rem;
    } */
    .cd-button span{
        cursor: pointer;
        color: var(--white);
        font-style: italic;
        font-size: 0.9rem;
    }
    .cd-content{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* overflow: auto; */
        background: #00000050;
        /* transition: 0.5s; */
        /* z-index: 10000000000; */
        display: none;
        padding: 2.5rem 1rem 2.5rem 1rem;
        user-select: text;
    }
    .cd-content-active{
        /* position: relative; */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* padding: 1rem; */
        /* overflow: auto; */
        display: block;
    }
    .cd-main-content{
        position: absolute;
        top: 2.5rem;
        right: 10%;
        bottom: 2.5rem;
        left: 10%;
        display: flex;
        color: var(--grey);
        background: var(--white);
        padding: 1rem;
        border-radius: 10px;
        -webkit-animation-name: animatemaindesc;
		-webkit-animation-duration: 0.5s;
		animation-name: animatemaindesc;
		animation-duration: 0.5s;
        overflow: auto;
        transition: 0.2s;
    }
    .cmc-content{
        color: var(--black);
    }
    .cmc-content h1{
        font-size: 2rem;
        font-family: poppins-bold;
        /* color: var(--black); */
    }
    .cmc-content p{
        font-family: poppins-regular;
        font-weight: 500;
        font-size: 1rem;
    }
    .cmc-content h2, h3, h4, h5, h6{
        font-family: poppins-bold;
    }
    @-webkit-keyframes animatemaindesc {
		from {top:200%; opacity:0}
		to {top:2.5rem; opacity:1}
	}
	@keyframes animatemaindesc {
		from {top:200%; opacity:0}
		to {top:2.5rem; opacity:1}
	}
    .cd-content-close{
        position: absolute;
        top: 0;
        left: 0;
        width: 37px;
        height: 37px;
        padding: 1rem;
        color: var(--white);
        cursor: pointer;
        z-index: 1;
        /* border: 1px solid green; */
    }
    .cmc-content{
        width: 70%;
        max-height: 100%;
        overflow: auto;
        padding: 0 0.5rem 0 0;
        scroll-behavior: smooth;
    }
    .cmc-index-button{
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem 2rem 0 0;
        background: var(--white);
        color: var(--primary);
        font-weight: 500;
        /* border-bottom: 1px solid var(--softgrey); */
        /* border-left: 1px solid var(--softgrey); */
        /* border-bottom-left-radius: 10px; */
        display: none;
        opacity: 0.9;
    }
    .cmc-index-shadow{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 150%;
        background: #00000050;
        display: none;
        z-index: 1;
    }
    .cmc-index{
        width: 30%;
        max-height: 100%;
        overflow: auto;
        padding: 0 0 0 0.5rem;
        background: var(--white);
        z-index: 5;
        transition: 0.2s;
    }
    .more{
        margin-top: 2rem;
    }
    .hh-right{
        position: relative;
        background: var(--primary);
        transition: 0.2s;
        display: flex;
        align-items: end;
    }
    .img-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .hh-right img{
        /* height: 100%; */
        width: 100%;
        transition: 0.2s;
        z-index: 0;
    }
    @media only screen and (max-width: 2150px){
        .home-header{
            height: 55rem;
        }
    }
    @media only screen and (max-width: 1775px){
        .home-header{
            height: 45rem;
        }
    }
    @media only screen and (max-width: 1500px){
        .home-header{
            height: 35rem;
        }
        .hh-left{
            padding-top: 2.6rem;
            padding-right: 2.6rem;
            padding-left: 5%;
        }
    }
    @media only screen and (max-width: 1150px){
        .home-header{
            height: 31rem;
        }
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2rem;
        }
        .hli-title{
            font-size: 1.5rem;
        }
        .hh-left-inner{
            padding: 0 0 2rem 0;
        }
    }
    @media only screen and (max-width: 1000px){
        .home-header{
            height: 27rem;
            /* border: 1px solid green; */
        }
        .hh-left{
            padding-top: 1rem;
            padding-right: 1rem;
        }
        /* .hh-left h1{
            font-size: 2rem;
        } */
        /* .hh-left-inner{
            padding: 0 0 2rem 0;
        } */
    }
    @media only screen and (max-width: 875px){
        .home-header{
            height: 20rem;
        }
        .hh-left h1{
            font-size: 1.5rem;
            width: auto;
        }
        .hh-left-inner p{
            font-size: 0.75rem;
        }
        /* .hh-right img{
            width: unset;
            height: 100%;
        } */
    }
    @media only screen and (max-width: 750px){
        .home-header{
            /* height: 41rem; */
        }
        .hh-left{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #1b467454;
            /* padding-top: 3.6rem; */
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .hh-left h1{
            font-size: 2rem;
            width: auto;
        }
        .hli-title{
            font-size: 1.2rem;
        }
        /* .hh-left-inner p{
            font-size: 0.75rem;
        } */
        .more a{
            background: var(--primary);
        }
        .hc-control{
            left: 0;
        }
        .cd-button span{
            color: var(--white);
        }
        .cd-main-content{
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 0;
            overflow: hidden;
            -webkit-animation-name: unset;
            animation-name: unset;
            padding: 2.5rem 1rem 1rem 1rem;
        }
        .cd-content-close{
            color: var(--primary);
        }
        .cmc-content{
            width: 100%;
        }
        .cmc-content p{
            font-size: 1rem;
        }
        .cmc-index-button{
            display: block;
        }
        .cis-active{
            display: block;
            right: 0;
        }
        .cmc-index{
            position: absolute;
            top: 0;
            right: -100%;
            width: 75%;
            height: 100%;
            border-left: 1px solid var(--softgrey);
            padding: 1rem 0 0 0.5rem;
        }
        .cmc-index-active{
            right: 0;
        }
        .hh-right{
            width: 100%;
        }
    }
    @media only screen and (max-width: 500px){
        .home-header{
            /* height: 31rem; */
        }
        .hh-left h1{
            font-size: 1.5rem;
            width: auto;
        }
        .hh-left-inner p{
            font-size: 0.75rem;
        }
        .cmc-content p{
            font-size: 1rem;
        }
        /* .hh-right img{
            width: unset;
            height: 100%;
        } */
    }
    @media only screen and (max-width: 350px){
        .home-header{
            height: 15rem;
        }
        .hh-left{
            padding-top: 1rem;
        }
    }
    @media only screen and (max-width: 350px){
        .hh-left-inner p{
            font-size: 0.5rem;
        }
        .cmc-content p{
            font-size: 1rem;
        }
    }
</style>