<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <SpCoriComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import SpCoriComponent from "../components/special-pages/SpCoriComponent.vue";
    import { addStructuredData, setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    import { useSpecialPageStore } from '@/stores/specialpage';
    import pageImage from '@/assets/images/pictures/coriheader.png';
    onMounted(() => {
        const pageData = {
            baseUrl: process.env.VUE_APP_BASE_URL,
            path: "/sp/robotic-total-knee-replacement-rsmelinda2",
            title: "Mengembalikan Kebebasan Bergerak dengan Robotic Total Knee Replacement di Rumah Sakit Melinda 2",
            description: "Robotic Total Knee Replacement adalah prosedur medis mutakhir yang menggunakan teknologi robotik untuk membantu ahli bedah dalam memasang implan lutut dengan presisi yang luar biasa.",
            publisher: {
                "@type": "Organization",
                "name": "Melinda Hospital",
                "logo": {
                "@type": "ImageObject",
                "url": "https://melindahospital.com/logo.png"
                }
            },
            datePublished: "2024-07-01T08:00:00+07:00",
            dateModified: "2024-09-23T08:00:00+07:00",
            image: pageImage,
            breadcrumb: [
                {
                    name: "Home",
                    url: process.env.VUE_APP_BASE_URL
                },
                {
                    name: "Mengembalikan Kebebasan Bergerak dengan Robotic Total Knee Replacement di Rumah Sakit Melinda 2",
                    url: process.env.VUE_APP_BASE_URL + '/sp/robotic-total-knee-replacement-rsmelinda2'
                },
            ]
        };
        setPageData({
            title: 'Mengembalikan Kebebasan Bergerak dengan Robotic Total Knee Replacement di Rumah Sakit Melinda 2',
            description: 'Kami berkomitmen dalam menyediakan layanan kesehatan kualitas terbaik.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/sp/robotic-total-knee-replacement-rsmelinda2'
            },
            ogData: {
                title: 'Mengembalikan Kebebasan Bergerak dengan Robotic Total Knee Replacement di Rumah Sakit Melinda 2',
                type: 'website',
                url: 'https://melindahospital.com/sp/robotic-total-knee-replacement-rsmelinda2',
                image: 'https://melindahospital.com/img/coripic.ab3d52c2.png'
            }
        });
        const breadcrumbData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": pageData.breadcrumb.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": item.url
            }))
        };
        const webPageData = {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": pageData.title,
            "description": pageData.description,
            "url": pageData.baseUrl + pageData.path,
            "publisher": pageData.publisher,
            // "mainEntity": {
            //     "@type": "Service",
            //     "serviceType": "Robotic Total Knee Replacement",
            //     "description": "Layanan penggantian sendi lutut yang dibantu oleh teknologi robotik canggih.",
            //     "provider": {
            //     "@type": "Organization",
            //     "name": "Melinda Hospital",
            //     "url": "https://melindahospital.com"
            //     },
            //     "offers": {
            //     "@type": "Offer",
            //     "priceCurrency": "IDR",
            //     "price": "140000000",
            //     "priceValidUntil": "2024-12-31",
            //     "url": "https://melindahospital.com/services/robotic-total-knee-replacement",
            //     "availability": "https://schema.org/InStock"
            //     }
            // },
            "datePublished": pageData.datePublished,
            "dateModified": pageData.dateModified,
            "image": pageData.baseUrl + pageData.image
        };
        useSpecialPageStore().reportConversion({slug: 'robotic-total-knee-replacement-rsmelinda2', path: '/sp/robotic-total-knee-replacement-rsmelinda2'});
        addStructuredData([breadcrumbData, webPageData]);
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        // {
        //     title: 'Artikel',
        //     type: 'link',
        //     to: '/articles'
        // },
        {
            title: 'Mengembalikan Kebebasan Bergerak dengan Robotic Total Knee Replacement di Rumah Sakit Melinda 2',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
