<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <HeaderComponent/>
        <AllArticlesComponent/>
        <!-- <TerbaruComponent/>
        <TerpopulerComponent/> -->
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import HeaderComponent from "../components/article/HeaderComponent.vue";
    import AllArticlesComponent from "../components/article/AllArticlesComponent.vue";
    // import TerbaruComponent from "../components/article/TerbaruComponent.vue";
    // import TerpopulerComponent from "../components/article/TerpopulerComponent.vue";
    // import { useArticleStore } from "@/stores/article";
    // import { computed } from "@vue/runtime-core";
    import { setPageData, addStructuredData } from '@/functions/general';
    import { onMounted, 
        // onUnmounted 
    } from 'vue';
    onMounted(() => {
        const pageData = {
            breadcrumb: [
                {
                    name: "Home",
                    url: process.env.VUE_APP_BASE_URL
                },
                {
                    name: "Artikel",
                    url: process.env.VUE_APP_BASE_URL + '/articles'
                },
            ]
        };
        setPageData({
            title: 'Melinda Hospital - Artikel',
            description: 'Halaman yang memberikan kemudahan akses untuk mendapatkan berita dan artikel terkini mengenai kesehatan, gaya hidup, dan informasi menarik lainnya dan tentunya informasi terbaru mengenai RSIA Melinda.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/articles'
            },
            ogData: {
                title: 'Melinda Hospital - Artikel',
                type: 'website',
                url: 'https://melindahospital.com/articles',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
        const breadcrumbData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": pageData.breadcrumb.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": item.url
            }))
        };
        addStructuredData([breadcrumbData]);
    });
    // onUnmounted(() => {
    //     if(document.body.scrollTop){
    //         useArticleStore().articlesPageScrollTop = document.body.scrollTop;
    //         console.log(`scroll value: ${document.body.scrollTop}`);
    //     }
    //     if(document.documentElement.scrollTop){
    //         useArticleStore().articlesPageScrollTop = document.documentElement.scrollTop;
    //         console.log(`scroll value: ${document.documentElement.scrollTop}`);
    //     }
    //     console.log('unmounted');
    // });
    // const scrollX = computed(() => useArticleStore().articlesPageScrollTop);
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Artikel',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
    // console.log(scrollX);
</script>
<style scoped>
    /*  */
</style>
