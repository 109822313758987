<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <HealthFacilityHead/>
        <HealthFacilityTileComponent/>
        <!-- <HealthFacilityComponent/> -->
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import HealthFacilityHead from "@/components/health-facility/HealthFacilityHead.vue";
    import HealthFacilityTileComponent from "@/components/health-facility/HealthFacilityTileComponent.vue";
    import { addStructuredData, setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        const pageData = {
            breadcrumb: [
                {
                    name: "Home",
                    url: process.env.VUE_APP_BASE_URL
                },
                {
                    name: "Fasilitas Kesehatan",
                    url: process.env.VUE_APP_BASE_URL + '/fasilitas-kesehatan'
                },
            ]
        };
        setPageData({
            title: 'Melinda Hospital - Fasilitas Kesehatan',
            description: 'Terdapat beberapa rumah sakit yang merupakan keluarga dari Melinda Hospital yang menyajikan info menarik, tetap ikuti perkembangan kami, dengan mengakses link tautan di bawah ini untuk mengetahui informasi dan layanan terbaru yang kami sediakan.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/fasilitas-kesehatan'
            },
            ogData: {
                title: 'Melinda Hospital - Fasilitas Kesehatan',
                type: 'website',
                url: 'https://melindahospital.com/fasilitas-kesehatan',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
        const breadcrumbData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": pageData.breadcrumb.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": item.url
            }))
        };
        addStructuredData([breadcrumbData]);
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Fasilitas Kesehatan',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
    // import HealthFacilityComponent from "@/components/health-facility/HealthFacilityComponent.vue";
</script>
<style scoped>
    /*  */
</style>
