import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ArticleView from '../views/ArticleView.vue';
import ArticleDetailView from '../views/ArticleDetailView.vue';
import DoctorView from '../views/DoctorView.vue';
// import DoctorSpecialistView from '../views/DoctorSpecialistView.vue';
import DoctorSpecialistByHealthFacilityView from '../views/DoctorSpecialistByHealthFacilityView.vue';
// import DoctorDetailView from '../views/DoctorDetailView.vue';
import DoctorProfileView from '../views/DoctorProfileView.vue';
import FindMedicineView from '../views/FindMedicineView.vue';
import MedicineCategoryView from '../views/MedicineCategoryView.vue';
import MedicineDetailView from '../views/MedicineDetailView.vue';
import CartView from '../views/CartView.vue';
import WishListView from '../views/WishListView.vue';
import OrderListView from '../views/OrderListView.vue';
import OrderDetailView from '../views/OrderDetailView.vue';
import OrderConfirmationView from '../views/OrderConfirmationView.vue';
import DirectOrderConfirmation from '../views/DirectOrderConfirmation.vue';
import OrderResultView from '../views/OrderResultView.vue';
// import ServicesView from '../views/ServicesView.vue';
// import ServiceCategoryView from '../views/ServiceCategoryView.vue';
import ServiceListByCategoryAndHealthFacilityView from '../views/ServiceListByCategoryAndHealthFacilityView.vue';
// import ServiceDetailView from '../views/ServiceDetailView.vue';
import CoeView from '../views/CoeView.vue';
import CoeCategoryView from '../views/CoeCategoryView.vue';
import CoeDetailView from '../views/CoeDetailView.vue';
import HealthFacilityView from '../views/HealthFacilityView.vue';
import HealthFacilityCategoryView from '../views/HealthFacilityCategoryView.vue';
// import HealthFacilityDetailView from '../views/HealthFacilityDetailView.vue';
import RegisterView from '../views/RegisterView.vue';
import ProfileView from '../views/ProfileView.vue';
// import TransactionView from '../views/TransactionView.vue';
import ContactView from '../views/ContactView.vue';
import NotFoundView from '../views/NotFoundView.vue';
// import { useAccountStore } from '@/stores/account';
import { getCookie } from '@/functions/general';
// import { computed } from "@vue/runtime-core";

import ShortenerView from '../views/ShortenerView.vue';
import EventAttendanceFormView from '../views/EventAttendanceFormView.vue';
import EventFormView from '../views/EventFormView.vue';
import EventAnnouncementView from '../views/EventAnnouncementView.vue';
import EventVerificationView from '../views/EventVerificationView.vue';
import EventVerificationScannerView from '../views/EventVerificationScannerView.vue';
// import EventAnnouncementStaticView from '../views/EventAnnouncementStaticView.vue';
import SurveyFormView from '../views/SurveyFormView.vue';
import SpCoriView from '../views/SpCoriView.vue';
import SpMcuView from '../views/SpMcuView.vue';
import ToolView from '../views/ToolView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/articles',
    name: 'article',
    component: ArticleView
  },
  {
    path: '/articles/detail/:slug',
    name: 'detailArticle',
    component: ArticleDetailView
  },
  {
    path: '/dokter',
    name: 'findDoctor',
    component: DoctorView
  },
  // {
  //   path: '/dokter/spesialis/:slug',
  //   name: 'doctorSpecialist',
  //   component: DoctorSpecialistView
  // },
  {
    path: '/dokter/:specialist/:slug',
    name: 'doctorDetail',
    component: DoctorProfileView
  },
  // {
  //   path: '/dokter/profil/:specialist/:slug',
  //   name: 'doctorProfile',
  //   component: DoctorProfileView
  // },
  // {
  //   path: '/dokter/profil/peninjau/:slug',
  //   name: 'doctorReviewer',
  //   component: DoctorProfileView
  // },
  {
    path: '/obat',
    name: 'findMedicine',
    component: FindMedicineView
  },
  {
    path: '/obat/kategori/:category',
    name: 'medicineCategory',
    component: MedicineCategoryView
  },
  {
    path: '/obat/detail/:detail',
    name: 'medicineDetail',
    component: MedicineDetailView
  },
  {
    path: '/order/wish-list',
    name: 'wishList',
    component: WishListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order/list',
    name: 'orderList',
    component: OrderListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order/detail/:slug',
    name: 'orderDetail',
    component: OrderDetailView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/order/:type/confirmation',
    name: 'orderConfirmation',
    component: OrderConfirmationView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/direct-order/service/confirmation',
    name: 'directOrderConfirmation',
    component: DirectOrderConfirmation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order/result',
    name: 'orderResult',
    component: OrderResultView,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/order/transactions',
  //   name: 'transaction',
  //   component: TransactionView,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/layanan',
  //   name: 'services',
  //   component: ServicesView
  // },
  // {
  //   path: '/layanan/kategori/:slug',
  //   name: 'serviceCategory',
  //   component: ServiceCategoryView
  // },
  // {
  //   path: '/layanan/detail/:slug',
  //   name: 'serviceDetail',
  //   component: ServiceDetailView
  // },
  {
    path: '/layanan',
    name: 'coe',
    component: CoeView
  },
  {
    path: '/layanan/kategori/:slug',
    name: 'coeCategory',
    component: CoeCategoryView
  },
  {
    path: '/layanan/detail/:slug',
    name: 'coeDetail',
    component: CoeDetailView
  },
  {
    path: '/fasilitas-kesehatan',
    name: 'healthFacility',
    component: HealthFacilityView
  },
  {
    path: '/fasilitas-kesehatan/kategori/:slug',
    name: 'healthFacilityCategory',
    component: HealthFacilityCategoryView
  },
  // {
  //   path: '/fasilitas-kesehatan/detail/:slug',
  //   name: 'healthFacilityDetail',
  //   component: HealthFacilityDetailView
  // },
  {
    path: '/fasilitas-kesehatan/layanan/:hf/:category',
    name: 'serviceListByCategoryAndHealthFacility',
    component: ServiceListByCategoryAndHealthFacilityView
  },
  {
    path: '/fasilitas-kesehatan/dokter/:hf/:specialist',
    name: 'doctorSpecialistByHealthFacility',
    component: DoctorSpecialistByHealthFacilityView
  },
  {
    path: '/account/register',
    name: 'register',
    component: RegisterView,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/account/password/create/:token',
    name: 'createPassword',
    component: RegisterView,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/account/verify/email/:token',
    name: 'emailVerify',
    component: RegisterView,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/account/profile',
    name: 'userProfile',
    component: ProfileView,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: AboutView
  // },
  {
    path: '/contact-us',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  // =====
  {
    path: '/s/:token',
    name: 'shortener',
    component: ShortenerView
    // {
    //   render: function(){
    //     const token = this.$route.params.token;
    //     useShortenerStore().getUrl(token);
    //   }
    // }
  },
  {
    path: '/attendance/form/:slug',
    name: 'eventAttendance',
    component: EventAttendanceFormView
  },
  {
    path: '/event/form/:slug',
    name: 'eventForm',
    component: EventFormView
  },
  {
    path: '/event/announcement/:slug',
    name: 'eventAnnouncement',
    component: EventAnnouncementView
  },
  {
    path: '/event/verification/:slug',
    name: 'eventVerification',
    component: EventVerificationView
  },
  {
    path: '/event/verification-scanner/:slug',
    name: 'eventVerificationScanner',
    component: EventVerificationScannerView
  },
  // {
  //   path: '/event/st-announcement/:slug',
  //   name: 'eventAnnouncementStatic',
  //   component: EventAnnouncementStaticView
  // },
  {
    path: '/survey/form/:slug',
    name: 'surveyForm',
    component: SurveyFormView
  },
  {
    path: '/sp/robotic-total-knee-replacement-rsmelinda2',
    name: 'spCori',
    component: SpCoriView
  },
  {
    path: '/sp/mcu-melinda-hospital',
    name: 'spMcu',
    component: SpMcuView
  },
  {
    path: '/mombabyclass',
    name: 'momBabyclass',
    redirect: '/event/form/moms-and-baby-class'
  },
  {
    path: '/tool',
    name: 'tool',
    component: ToolView
  },
  {
    path: '/business-card/susan-melinda',
    name: 'bCardDrSusan',
    // meta: {
    //   staticPage: true
    // },
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/dr-susan-melinda.html'
    }
  },
  {
    path: '/business-card/dr-grace',
    name: 'bCardDrGrace',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/dr-grace.html'
    }
  },
  // {
  //   path: '/business-card/efni-elita',
  //   name: 'bCardEfniElita',
  //   beforeEnter(){
  //     location.href = process.env.BASE_URL + 'business-card/efni-elita.html'
  //   }
  // },
  {
    path: '/business-card/sanny',
    name: 'bCardSanny',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/sanny.html'
    }
  },
  // {
  //   path: '/business-card/dr-tammy-siarif',
  //   name: 'bCardDrTammy',
  //   beforeEnter(){
  //     location.href = process.env.BASE_URL + 'business-card/dr-tammy-siarif.html'
  //   }
  // },
  {
    path: '/business-card/cardiac-rehabilitation',
    name: 'cardiacRehabilitation',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/cardiac-rehabilitation.html'
    }
  },
  {
    path: '/business-card/dr-ellyana',
    name: 'drEllyana',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/dr-ellyana.html'
    }
  },
  {
    path: '/business-card/melinda-cvc',
    name: 'melindCvc',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/melinda-cvc.html'
    }
  },
  {
    path: '/business-card/dr-sella',
    name: 'melindCvc',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/dr-sella.html'
    }
  },
  {
    path: '/business-card/chandra-tri-saputra',
    name: 'bCardChandra',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'business-card/chandra-tri-saputra.html'
    }
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: NotFoundView
  },
  {
    path: '/:catchAll(.*)',
    // redirect: '/not-found',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'not-found'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // const loggedIn = useAccountStore().loggedIn;
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!getCookie('session')) {
        next({ name: 'home' });
      } else {
        next();
      }
  } else {
    if (to.matched.some(record => record.meta.hideForAuth)) {
        if (getCookie('session')) {
          // console.log('hide');
          next({ name: 'home' });
        } else {
          next();
        }
    } else {
      next();
    }
    // next();
  }
});

export default router
