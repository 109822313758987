<template>
    <EventFormComponent/>
</template>

<script setup>
    import EventFormComponent from '@/components/event/EventFormComponent.vue';
    scrollTo(0, 0);
</script>

<style scoped>
    /*  */
</style>