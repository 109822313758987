<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <FindDoctorHeader/>
        <SpecialistListComponent/>
        <!-- <DoctorListComponent/> -->
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import FindDoctorHeader from "../components/doctor/FindDoctorHeader.vue";
    import SpecialistListComponent from "../components/doctor/SpecialistListComponent.vue";
    import { setPageData, addStructuredData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        const pageData = {
            breadcrumb: [
                {
                    name: "Home",
                    url: process.env.VUE_APP_BASE_URL
                },
                {
                    name: "Dokter",
                    url: process.env.VUE_APP_BASE_URL + '/dokter'
                },
            ]
        };
        setPageData({
            title: 'Melinda Hospital - Cari Dokter',
            description: 'Melinda Hospital menghadirkan dokter-dokter spesialis yang sangat berpengalaman, yang didukung oleh tenaga ahli di bidangnya masing-masing sehingga dapat memberikan yang terbaik untuk para pasien.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/dokter'
            },
            ogData: {
                title: 'Melinda Hospital - Cari Dokter',
                type: 'website',
                url: 'https://melindahospital.com/dokter',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
        const breadcrumbData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": pageData.breadcrumb.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": item.url
            }))
        };
        addStructuredData([breadcrumbData]);
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Cari Dokter',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
    // import DoctorListComponent from "../components/doctor/DoctorListComponent.vue";
</script>
<style scoped>
    /*  */
</style>