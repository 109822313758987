<template>
    <section>
        <div class="section-inner">
            <div class="section-inner-part sip-desc">
                <h1>Layanan Unggulan</h1>
                <p>Melinda Hospital selalu meningkatkan kualitas pelayanan yang terbaik untuk masyarakat. Kami memiliki fasilitas kesehatan yang inovatif yang menyediakan layanan spesialis dan sub-spesialis, peralatan medis yang sudah sesuai standar, dengan teknologi modern. Pelayanan terbaik dari Melinda Hospital dapat membantu mengurangi segala kecemasan atau keraguan sehingga kami dapat memberikan solusi yang tepat dalam mengatasi masalah kesehatan anda.</p>
            </div>
            <div class="section-inner-part sip-search" id="sip-search">
                <!-- <div class="sip-search-input">
                    <input type="text" id="input-search-services" placeholder="Nama Layanan" @input="searchServices" />
                </div>
                <div class="sip-search-button">
                    <span>CARI</span>
                </div> -->
            </div>
        </div>
        <div class="section-search">
            <div class="ss-select" id="select-svcategory">
                <div class="ss-select-button trigger" @click="selectToggle('select-svcategory'); inputFocus('input-select-svcategory'); elementScrollTop('sso-list-specialist');">
                    <div class="ssb-text trigger" v-if="categories && allServices">
                        <span class="trigger">{{ searchbar.select.title }}</span>
                    </div>
                    <div class="ssb-text trigger" v-else>
                        <span class="trigger">Loading</span>
                    </div>
                    <div class="ssb-icon trigger option-icon">
                        <img class="trigger" src="../../assets/images/icons/arrow-1.png" alt="melinda care"/>
                    </div>
                </div>
                <div class="ss-select-option option trigger" v-if="categories && allServices">
                    <div class="sso-search trigger">
                        <div class="sso-search-inner trigger">
                            <div class="sso-search-input trigger">
                                <input type="text" class="input-select trigger" id="input-select-svcategory" @input="inputSelectCategory" placeholder="masukkan kategori" autocomplete="off"/>
                            </div>
                            <div class="sso-search-close trigger">
                                <div class="ssc-button trigger" @click="clearInputSelectCategory">
                                    <span class="trigger">x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sso-list trigger" id="sso-list-specialist" v-if="categories.length">
                        <div class="sso-list-part" @click="selectCategory({id: null, name: 'Semua', slug: 'all'}, 'input-select-svcategory'); selectToggle('select-svcategory');">
                            <span>Semua</span>
                        </div>
                        <div class="sso-list-part" v-for="sp in categories" :key="sp" @click="selectCategory(sp, 'input-select-svcategory'); selectToggle('select-svcategory');">
                            <span>{{ sp.name }}</span>
                        </div>
                    </div>
                    <div class="sso-list trigger" v-else>
                        <div class="sso-list-notfound trigger">
                            <span class="trigger">Tidak ditemukan kategori</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ss-input">
                <div class="ss-input-inner" v-if="categories && allServices">
                    <div class="ss-input-input">
                        <input type="text" id="sii-input-specialist" @input="inputSpecialist" :value="searchbar.input.value" placeholder="masukkan nama layanan" autocomplete="off"/>
                    </div>
                    <div class="ss-input-clear">
                        <div class="sic-button" @click="clearInputCategory" v-if="searchbar.input.value">
                            <span>x</span>
                        </div>
                    </div>
                </div>
                <div class="ss-input-inner" v-else>
                    <div class="sii-loading">
                        <p>Loading</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
    import { inputSelectCategory, inputSpecialist, selectCategory, clearInputCategory, clearInputSelectCategory, autoSelectCategory } from '@/functions/coe';
    import { computed } from "@vue/runtime-core";
    import { useCoeStore } from "@/stores/coe";
    import { selectToggle, inputFocus, elementScrollTop } from '@/functions/general';
    import { onMounted } from 'vue';
    const allServices = computed(() => useCoeStore().allServices);
    const searchbar = computed(() => useCoeStore().searchbarCategory);
    const categories = computed(() => useCoeStore().filteredCategories);
    const autoSelectCategoryData = computed(() => useCoeStore().autoSelectCategoryData);
    onMounted(() => {
        if(autoSelectCategoryData){
            autoSelectCategory();
        }
    });
    // function searchServices(){
    //     const is = document.getElementById('input-search-services');
    //     const ss = document.getElementById('sip-search');
    //     if(is.value){
    //         ss.classList.add('sip-search-fixed');
    //     }else{
    //         ss.classList.remove('sip-search-fixed');
    //     }
    // }
</script>
<style scoped>
    section{
        padding-top: 0;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 1rem;
    }
    .section-inner{
        display: flex;
        color: var(--white);
    }
    .section-inner h1{
        font-family: poppins;
        margin: 0;
    }
    .section-inner-part{
        width: 100%;
    }
    .sip-search{
        position: relative;
        display: none;
        /* padding: 5%; */
        padding-left: 2.5%;
        align-items: center;
        /* border: 1px solid green; */
        transition: position 0.5s;
    }
    .sip-search-fixed{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 15;
        background: #ffffff;
        align-items: baseline;
        padding: 5%;
        padding-left: 15%;
        padding-right: 15%;
    }
    .sip-search-input{
        width: 75%;
        height: 2rem;
    }
    .sip-search-input input{
        width: 100%;
        height: 100%;
        outline: none;
        border: 1px solid var(--white);
        border-right: none;
        padding: 1%;
        padding-left: 2%;
        padding-right: 2%;
    }
    .sip-search-button{
        display: flex;
        width: 25%;
        height: 2rem;
        /* text-align: center; */
        align-items: center;
        justify-content: center;
        /* border: 1px solid green; */
        cursor: pointer;
        background: var(--softgrey);
        color: var(--grey);
        font-family: poppins;
    }
    .section-inner h1{
        font-family: poppins;
    }
    .section-inner p{
        font-family: poppins-light;
        font-weight: 600;
    }
    .section-search{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background: var(--white);
        color: var(--grey);
        border: 1px solid var(--softgrey);
        transition: 0.2s;
    }
    .ss-select{
        position: relative;
        width: 40%;
        height: 75px;
        border-right: 1px solid var(--softgrey);
        user-select: none;
        transition: 0.2s;
    }
    .ss-select-button{
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0.5rem;
    }
    .ssb-text{
        width: 90%;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .ssb-icon{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .ssb-icon img{
        transition: 0.2s;
    }
    .option-icon-active img{
        transform: rotate(180deg);
    }
    .ss-select-option{
        width: 100%;
        position: absolute;
        top: 110%;
        left: 0;
        background: var(--white);
        display: none;
        border: 1px solid var(--softgrey);
        z-index: 5;
        animation-name: animateCategory;
		animation-duration: 0.2s;
    }
    .option-active{
        display: block;
    }
    .sso-search{
        padding: 0.5rem;
        height: 50px;
    }
    .sso-search-inner{
        width: 100%;
        height: 100%;
        display: flex;
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .sso-search-input{
        width: 90%;
    }
    .sso-search-input input{
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 5px;
        outline: none;
        padding: 1%;
    }
    .sso-search-close{
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid green; */
    }
    .ssc-button{
        width: 20px;
        height: 20px;
        display: none;
        justify-content: center;
        align-items: center;
        line-height: 1;
    }
    .sso-list{
        max-height: 450px;
        overflow: auto;
    }
    .sso-list-part{
        min-height: 45px;
        padding: 0.5rem;
    }
    .sso-list-part:hover{
        background: var(--smoke);
    }
    .option-button-active{
        background: var(--smoke);
    }
    .sso-list-notfound{
        padding: 0.5rem;
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        font-size: 0.9rem;
        color: var(--softgrey);
        text-align: center;
    }
    .ss-input{
        width: 60%;
        height: 75px;
        transition: 0.2s;
    }
    .ss-input-inner{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .ss-input-input{
        width: 90%;
        height: 100%;
    }
    .ss-input-input input{
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        padding: 1%;
    }
    .sii-loading{
        padding: 0.5rem;
    }
    .ss-input-clear{
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sic-button{
        width: 37px;
        height: 37px;
        border-radius: 37px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        /* display: none; */
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .section-inner{
            display: inline;
            color: var(--grey);
        }
        /* .section-inner-part{
            width: 100%;
        } */
        .sip-search{
            padding-left: 0;
        }
        .sip-search-fixed{
            padding: 5%;
        }
        .section-search{
            border: none;
        }
        .ss-select{
            width: 100%;
            border: 1px solid var(--softgrey);
        }
        .ss-input{
            width: 100%;
            border: 1px solid var(--softgrey);
            border-top: none;
        }
    }
</style>