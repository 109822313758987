<template>
    <section>
        <div class="section-content" v-if="articleDetail">
            <div class="sc-head">
                <p class="small-title">Melinda Hospital</p>
                <h1 class="main-title" id="index-h1-0">
                    <strong>{{ articleDetail.name }}</strong>
                </h1>
            </div>
            <div class="sc-inner" v-if="articleDetail">
                <div class="sci-left">
                    <!-- {{ articleDetail }} -->
                    <div class="sl-head">
                        <!-- <p class="p-date">Melinda Hospital, 27 Juni 2022</p> -->
                    </div>
                    <div class="sl-body">
                        <div class="sl-img">
                            <img :src="articleDetail.thumb_url" :alt="articleDetail.name"/>
                        </div>
                        <div class="sl-content ql-container ql-snow" style="position: relative;">
                            <div class="sl-content-team">
                                <div class="sct-member sct-author" v-if="articleDetail.ex_author">
                                    <div class="sca-param">Penulis: </div>
                                    <div class="sca-value"> {{ articleDetail.ex_author }}</div>
                                </div>
                                <div class="sct-member sct-reviewer" v-if="articleDetail.reviewer">
                                    <div class="sca-param">Ditinjau Oleh: </div>
                                    <div class="sca-value">
                                        <router-link :to="'/dokter/peninjau/' + articleDetail.reviewer_slug">{{ articleDetail.reviewer }}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="sl-content-text ql-editor" id="sl-content-text" v-html="articleDetail.content"></div>
                        </div>
                        <div class="reference" v-if="articleDetail.citations?.length">
                            <hr/>
                            <h2>Referensi</h2>
                            <ol>
                                <template v-for="rf in articleDetail.citations" :key="rf">
                                    <li v-if="rf.cite">
                                        <div v-html="rf.cite" :id="'reference-' + rf.ref"></div>
                                    </li>
                                    <!-- <a :href="rf.href" :id="'reference' + stringToHash(rf.href)" rel="nofollow" target="_blank">{{ rf.host }}</a> -->
                                </template>
                            </ol>
                        </div>
                    </div>
                    <!-- <div class="sc-share">
                        <h2>Share to</h2>
                        <div class="sc-share-inner">
                            <div class="scs-part">
                                <a href="https://www.facebook.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-facebook.png" alt="facebook icon"/>
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.twitter.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-twitter.png" alt="x icon"/>
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.linkedin.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-linkedin.png" alt="linkedin icon"/>
                                </a>
                            </div>
                            <div class="scs-part">
                                <a href="https://www.instagram.com" target="_blank">
                                    <img src="../../assets/images/icons/cc-instagram.png" alt="instagram icon"/>
                                </a>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="sci-right">
                    <div class="cov-sr-index" id="cov-sr-index">
                        <div class="sr-index-icon" id="sr-index-icon" @click="soiIndexToggle();" v-if="articleDetailIndex">
                            <span>Daftar Isi</span>
                        </div>
                        <div class="sr-index" id="sr-index">
                            <div class="sr-index-title" v-if="articleDetailIndex">Daftar Isi</div>
                            <div class="soi-index" id="soi-index"></div>
                        </div>
                    </div>
                    <!-- <template v-if="articleDetail.relateds"> -->
                        <div class="sr-others" v-if="articleDetail.relateds?.length">
                            <div class="sr-title">Artikel Terkait</div>
                            <div class="sr-others-inner">
                                <router-link :to="'/articles/detail/' + related.slug" v-for="related in articleDetail.relateds" :key="related" @click="useArticleStore().setArticleDetail(related.slug); pageScrollTop();">
                                    <div class="soi-part">
                                        <div class="soi-part-image">
                                            <img :src="related.thumb_small" :alt="related.name"/>
                                        </div>
                                        <div class="soi-part-detail">
                                            <h2>{{ wordsLimit(related.name, 90) }}</h2>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <ArticleDetailRelatedSkeleton v-else/>
                        <div class="sr-others" v-if="articleDetail.same_category?.length">
                            <div class="sr-title">Kategori {{ articleDetail.cat_name }}</div>
                            <div class="sr-others-inner">
                                <router-link :to="'/articles/detail/' + sc.slug" v-for="sc in articleDetail.same_category" :key="sc" @click="useArticleStore().setArticleDetail(sc.slug); pageScrollTop();">
                                    <div class="soi-part">
                                        <div class="soi-part-image">
                                            <img :src="sc.thumb_small" :alt="sc.name"/>
                                        </div>
                                        <div class="soi-part-detail">
                                            <h2>{{ wordsLimit(sc.name, 90) }}</h2>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    <!-- </template> -->
                    <div class="sr-categories" v-if="articleCategories">
                        <div class="sr-title">Kategori</div>
                        <div class="sr-categories-inner">
                            <div class="sr-link" v-for="category in articleCategories.data" :key="category.id">
                                <router-link :to="'/articles'">
                                    <h3 @click="setAutoSelectCategoryData(category);">{{ category.name }}</h3>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="sr-part sr-tag">
                        <h2>Tags</h2>
                        <div class="sr-tag-inner" v-if="articleDetail">
                            <router-link to="" v-for="tag in articleDetail.tags" :key="tag.id">{{ tag.name }}</router-link>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <ArticleDetailSkeleton v-else/>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    import ArticleDetailSkeleton from "../skeletons/ArticleDetailSkeleton.vue";
    import ArticleDetailRelatedSkeleton from "../skeletons/parts/ArticleDetailRelatedSkeleton.vue";
    import { checkPath, setAutoSelectCategoryData, soiIndexToggle } from '@/functions/article';
    import { pageScrollTop, wordsLimit, 
        // stringToHash 
    } from '@/functions/general';
    const articleDetail = computed(() => useArticleStore().articleDetail);
    const articleDetailIndex = computed(() => useArticleStore().articleDetailIndex);
    const articleCategories = computed(() => useArticleStore().articleCategories);
    useArticleStore().setArticleDetail(useRoute().params.slug);
    useArticleStore().setArticleCategories();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.navigation.addEventListener("navigate", (event) => {
        var url = new URL(event.destination.url);
        checkPath(url.pathname);
        // useArticleStore().setArticleDetail(useRoute().params.slug);
        // console.log('location changed!');
        // console.log(url);
    });
</script>

<style scoped>
    @import url('../../assets/css/ql-editor.css');
    @import url('../../assets/css/article_detail_index.css');
    .ql-snow .ql-editor img {
        max-width: 100%;
    }
    section{
        position: relative;
        width: 100%;
        min-height: 15rem;
        padding-top: 0;
        padding-bottom: 2.5rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .sl-head{
        color: var(--white);
    }
    .small-title{
        font-weight: 600;
        font-size: 1.125rem;
        margin: 0;
    }
    .main-title{
        font-family: poppins;
        font-size: 3.25rem;
        font-weight: 700;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: 0.2s;
        line-height: 1.2;
    }
    .p-date{
        margin: 0;
        margin-top: 1.6875rem;
        font-size: 1rem;
        font-weight: 400;
        width: 31.9375rem;
        transition: 0.2s;
    }
    .section-content{
        width: 100%;
        height: 100%;
        padding-left: 10%;
        padding-right: 10%;
        transition: 0.2s;
    }
    .sc-head{
        color: var(--white);
    }
    .sc-inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        /* padding-top: 2rem; */
        transition: 0.2s;
        background: var(--white);
    }
    .sci-left{
        width: 60%;
        transition: 0.2s;
    }
    .sl-img{
        min-height: 250px;
    }
    .sl-img img{
        width: auto;
        width: 100%;
        transition: 0.2s;
    }
    .sl-content{
        text-align: justify;
        color: var(--grey);
        font-family: poppins-regular;
    }
    .sl-content-team{
        width: 100%;
        display: flex;
        padding: 1rem 0 0 0;
    }
    .sl-content img{
        max-width: 100%;
    }
    .sct-member{
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    .sct-reviewer{
        text-align: right;
        justify-content: right;
    }
    .sca-value{
        font-weight: 700;
        color: var(--navy);
    }
    .sl-content-text{
        padding: 1rem 0 0 0;
    }
    .slc-part{
        margin-bottom: 2rem;
    }
    .slc-part h2{
        font-family: poppins;
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .cite{
        color: var(--primary) !important;
        cursor: pointer;
    }
    .slc-part ul{
        list-style-type: none;
        padding-left: 0;
    }
    .slc-part ol{
        padding-left: 1rem;
    }
    .slc-part li{
        font-size: 1rem;
    }
    .sc-share{
        margin-top: 2rem;
    }
    .sc-share h2{
        font-family: poppins;
        font-size: 1rem;
        font-weight: 700;
    }
    .sc-share-inner{
        display: flex;
        flex-wrap: nowrap;
        padding-top: 1rem;
    }
    .scs-part{
        margin-right: 1rem;
    }
    .sci-right{
        width: 40%;
        padding-left: 2.5rem;
        transition: 0.2s;
    }
    .cov-sr-index{
        max-height: 500px;
        transition: 0.5s;
    }
    .sr-index-icon{
        position: fixed;
        right: -200px;
        bottom: 100px;
        z-index: 1;
        transition: 0.5s;
        cursor: pointer;
        user-select: none;
        border: 1px solid var(--softgrey);
        padding: 0.5rem 1rem 0.5rem 1rem;
        background: var(--white);
    }
    .sii-scrolled{
        right: 10%;
    }
    .sr-index{
        transition: 0.5s;
        z-index: 5;
    }
    .sr-index-scrolled{
        position: fixed;
        top: 100px;
        right: -100%;
        bottom: 150px;
        width: 350px;
        overflow: auto;
        background: var(--white);
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        padding: 0.5rem;
    }
    .sr-index-active{
        right: 10%;
    }
    .sr-index-title{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: var(--navy);
        padding: 1rem 0 1rem 0;
    }
    .sr-index-scrolled .sr-index-title{
        display: none;
    }
    .soi-index{
        max-height: 350px;
        overflow: auto;
    }
    .soi-index-scrolled{
        max-height: unset;
        overflow: unset;
    }
    .sr-title{
        font-family: poppins;
        font-weight: 700;
        font-size: 1.25rem;
        color: var(--navy);
        padding: 1rem 0 1rem 0;
    }
    .sr-others a{
        color: var(--navy);
    }
    .sr-others a:hover{
        color: var(--navy-hover);
    }
    .soi-part{
        display: flex;
        width: 100%;
        padding: 0.5rem 0 0.5rem 0;
    }
    .soi-part-image{
        width: 25%;
        overflow: hidden;
        display: flex;
        align-items: center;
    }
    .soi-part-image img{
        width: 100%;
    }
    .soi-part-detail{
        width: 75%;
        padding: 0 0.5rem 0 1rem;
        display: flex;
        align-items: center;
    }
    .soi-part-detail h2{
        margin: 0 0 0 0;
        line-height: 1;
        font-size: 0.9rem;
    }
    .soi-part-detail p{
        font-family: arial;
        color: var(--grey);
    }
    .sr-categories{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-link{
        margin-top: 1rem;
    }
    .sr-link a{
        color: #1B4674;
        text-decoration: none;
    }
    .sr-tag{
        width: 100%;
        transition: 0.2s;
        margin-bottom: 2rem;
    }
    .sr-tag-inner{
        display: flex;
        flex-wrap: wrap;
        padding-top: 1rem;
        width: 100%;
    }
    .sr-tag-inner a{
        font-size: 1rem;
        color: #575757;
        border: 1px solid #575757;
        border-radius: 50px;
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-decoration: none;
        margin-top: 1rem;
        margin-right: 1rem;
    }
    @media only screen and (max-width: 1500px){
        .section-content{
            padding-left: 5%;
            padding-right: 5%;
        }
        .main-title{
            font-size: 2.6rem;
        }
        .sii-scrolled{
            right: 5%;
        }
        .sr-index-active{
            right: 5%;
        }
    }
    @media only screen and (max-width: 1050px){
        .sc-inner{
            padding-top: 0;
        }
        .sci-left{
            width: 100%;
        }
        .sl-img img{
            width: 100%;
        }
        .main-title{
            font-size: 2rem;
        }
        .p-date{
            width: auto;
        }
        .sci-right{
            /* display: flex; */
            flex-wrap: wrap;
            width: 100%;
            padding-top: 2rem;
            padding-left: 0;
        }
        .cov-sr-index{
            height: auto;
        }
        .sr-index-icon{
            position: fixed;
            right: 5%;
            bottom: 100px;
            z-index: 1;
            transition: 0.5s;
            cursor: pointer;
            user-select: none;
            border: 1px solid var(--softgrey);
            padding: 0.5rem 1rem 0.5rem 1rem;
            background: var(--white);
        }
        .sr-index{
            position: fixed;
            top: 100px;
            right: -200%;
            bottom: 150px;
            width: 350px;
            overflow: auto;
            background: var(--white);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
            padding: 0.5rem;
        }
        .sr-index-active{
            right: 2.5%;
        }
        .sr-index-title{
            padding: 0;
        }
        .sr-check{
            margin-bottom: 1.5rem;
        }
        .sr-categories{
            width: 50%;
            margin-bottom: 0;
        }
        .sr-tag{
            width: 50%;
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 750px){
        .sl-head{
            color: var(--grey);
        }
        .sc-head{
            color: var(--grey);
        }
        .small-title{
            font-size: 1rem;
        }
        .main-title{
            font-size: 1.5rem;
        }
        .section-content{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .sl-content-team{
            display: block;
        }
        .sct-member{
            width: 100%;
        }
        .sct-reviewer{
            text-align: left;
        }
        .sci-right{
            padding-top: 1rem;
        }
        .sr-index-icon{
            right: 2.5%;
        }
        .sr-check{
            padding: 0;
            margin-bottom: 1rem;
            box-shadow: unset;
            border-radius: 0;
        }
        .sr-categories{
            width: 100%;
        }
        .sr-tag{
            width: 100%;
        }
    }
    /*  */
</style>