<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <CoeHeaderComponent/>
        <CoeCategoryListComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import CoeHeaderComponent from "../components/coe/CoeHeaderComponent.vue";
    import CoeCategoryListComponent from "../components/coe/CoeCategoryListComponent.vue";
    import { addStructuredData, setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        const pageData = {
            breadcrumb: [
                {
                    name: "Home",
                    url: process.env.VUE_APP_BASE_URL
                },
                {
                    name: "Layanan",
                    url: process.env.VUE_APP_BASE_URL + '/layanan'
                },
            ]
        };
        setPageData({
            title: 'Melinda Hospital - Layanan',
            description: 'Melinda Hospital selalu meningkatkan kualitas pelayanan yang terbaik untuk masyarakat. Kami memiliki fasilitas kesehatan yang inovatif yang menyediakan layanan spesialis dan sub-spesialis, peralatan medis yang sudah sesuai standar, dengan teknologi modern.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/layanan'
            },
            ogData: {
                title: 'Melinda Hospital - Layanan',
                type: 'website',
                url: 'https://melindahospital.com/layanan',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
        const breadcrumbData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": pageData.breadcrumb.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": item.url
            }))
        };
        addStructuredData([breadcrumbData]);
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Layanan Unggulan',
            type: 'text'
        }
    ];
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>
