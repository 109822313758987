<template>
    <div class="content">
        <!-- <TopBackgroundComponent/> -->
        <HomeTopComponent/>
        <HomeSpecialistListComponent/>
        <HomeCoeComponent/>
        <!-- <AdditionalHeadComponent/> -->
        <OurSubsidiariesComponent/>
        <!-- <CrumbComponent :data="crumbdata"/> -->
        <!-- <HomeTopBackgroundComponent/> -->
        <!-- <CoeCategoryListComponent/> -->
        <!-- <HomeHeaderComponent/> -->
        <HomeDoctorComponent/>
        <HomeArticlesComponent/>
        <!-- <HomeHospitalComponent/> -->
    </div>
</template>
<script setup>
    // import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import HomeTopComponent from "../components/home/HomeTopComponent.vue";
    import HomeSpecialistListComponent from "@/components/home/HomeSpecialistListComponent.vue";
    // import AdditionalHeadComponent from "@/components/home/AdditionalHeadComponent.vue";
    import OurSubsidiariesComponent from "../components/home/OurSubsidiariesComponent.vue";
    import HomeCoeComponent from "../components/home/HomeCoeComponent.vue";
    import { addStructuredData, formatDateWithTimezone, removeHTML, removeStructuredData, setPageData } from '@/functions/general';
    import { onMounted, onUnmounted } from 'vue';
    import HomeDoctorComponent from "../components/home/HomeDoctorComponent.vue";
    import HomeArticlesComponent from "../components/home/HomeArticlesComponent.vue";
    // import HomeHospitalComponent from "../components/home/HomeHospitalComponent.vue";
    import { site } from '@/equipments/settings';
    import { useSpecialPageStore } from '@/stores/specialpage';
    import articleImage from '@/assets/images/pictures/melindahospitals.png';
    import publisherLogo from '@/assets/images/logos/mhlogo.webp';
    onMounted(() => {
        removeStructuredData();
        const homeArticle = document.getElementById('cmc-content');
        const pageData = {
            baseUrl: process.env.VUE_APP_BASE_URL,
            siteName: "Melinda Hospital",
            // contactUrl: `${process.env.VUE_APP_BASE_URL}/contact-us`,
            breadcrumb: [
                {
                    name: "Home",
                    url: process.env.VUE_APP_BASE_URL
                }
            ],
            organizationName: "Melinda Hospital Group",
            description: "Melinda Hospital Group is a leading healthcare provider in Bandung, offering modern medical services and expert care.",
            logoPath: "/img/logo.png",
            contactNumber: "+62-22-4222788",
            contactEmail: "contact@melindahospital.com",
            address: {
                street: "Jl. Pajajaran no 46",
                city: "Bandung",
                region: "Jawa Barat",
                postalCode: "40171",
                country: "ID"
            },
            geo: {
                latitude: -6.914744,
                longitude: 107.609810
            },
            openingHours: "Mo-Su 00:00-23:59",
            services: [
                {
                    name: "Robotic Total Knee Replacement",
                    description: "Prosedur penggantian lutut dengan bantuan robot yang menawarkan pemulihan cepat dan presisi tinggi.",
                    url: `${process.env.VUE_APP_BASE_URL}/sp/robotic-total-knee-replacement-rsmelinda2`
                },
                {
                    name: "Medical Check-Up",
                    description: "Paket pemeriksaan kesehatan lengkap untuk memantau kondisi kesehatan secara menyeluruh.",
                    url: `${process.env.VUE_APP_BASE_URL}/sp/mcu-melinda-hospital`
                },
            ],
            socialMedia: {
                facebook: "https://www.facebook.com/melindahospital",
                twitter: "https://twitter.com/melindahospital"
            },
            article: {
                title: site.title,
                description: site.description,
                author: "Melinda Hospital",
                datePublished: formatDateWithTimezone(new Date("2024-07-01"), "+07:00"),
                dateModified: formatDateWithTimezone(new Date("2024-07-01"), "+07:00"),
                content: removeHTML(homeArticle.innerHTML),
                image: articleImage,
                logo: publisherLogo,
                url: process.env.VUE_APP_BASE_URL
            },
        };
        setPageData({
            title: site.title,
            description: 'Melinda Hospital adalah jaringan rumah sakit terbaik di Kota Bandung, yang telah melayani masyarakat dengan dedikasi tinggi selama lebih dari dua dekade.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com'
            },
            ogData: {
                title: site.title,
                type: 'website',
                url: 'https://melindahospital.com',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
        useSpecialPageStore().reportConversion({slug: 'home', path: ''});
        const websiteData = {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": pageData.siteName,
            "url": pageData.baseUrl,
            "inLanguage": "id",
                "publisher": {
                "@type": "Organization",
                "name": pageData.organizationName,
                "logo": {
                    "@type": "ImageObject",
                    "url": `${pageData.baseUrl}${pageData.logoPath}`
                }
            },
        };
        const localBusinessData = {
            "@context": "https://schema.org",
            "@type": "Hospital",
            "name": "Melinda Hospital",
            "image": `${pageData.baseUrl}${pageData.logoPath}`,
            "url": pageData.baseUrl,
            "telephone": pageData.contactNumber,
            "priceRange": "$$$",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": pageData.address.street,
                "addressLocality": pageData.address.city,
                "addressRegion": pageData.address.region,
                "postalCode": pageData.address.postalCode,
                "addressCountry": pageData.address.country
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": -6.914744,
                "longitude": 107.609810
            },
            "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
                ],
                "opens": "00:00",
                "closes": "23:59"
            },
            "sameAs": [
                "https://www.instagram.com/melindahospital",
                "https://www.facebook.com/melindahospital"
            ],
            "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "Customer Service",
                "telephone": pageData.contactNumber,
                "email": pageData.contactEmail,
                "areaServed": "ID",
                "availableLanguage": ["Indonesian", "English"]
            },
            "service": pageData.services
        };
        const breadcrumbData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": pageData.breadcrumb.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": item.url
            }))
        };
        const organizationData = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": pageData.organizationName,
            "url": pageData.baseUrl,
            "logo": `${pageData.baseUrl}${pageData.logoPath}`,
            "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "Customer Service",
                "telephone": pageData.contactNumber,
                "email": pageData.contactEmail,
                "areaServed": "ID",
                "availableLanguage": ["Indonesian", "English"]
            },
            "address": {
                "@type": "PostalAddress",
                "streetAddress": pageData.address.street,
                "addressLocality": pageData.address.city,
                "addressRegion": pageData.address.region,
                "postalCode": pageData.address.postalCode,
                "addressCountry": pageData.address.country
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": -6.914744,
                "longitude": 107.609810
            },
            "openingHours": pageData.openingHours,
            "makesOffer": pageData.services.map(service => ({
                "@type": "Offer",
                "itemOffered": {
                "@type": "Service",
                "name": service.name,
                "description": service.description,
                "url": `${service.url}`
                }
            })),
            "sameAs": [
                pageData.socialMedia.facebook,
                pageData.socialMedia.twitter
            ]
        };
        const articleData = {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": pageData.article.title,
            "author": {
                "@type": "Organization",
                "name": pageData.article.author,
                "url": pageData.baseUrl
            },
            "datePublished": pageData.article.datePublished,
            "dateModified": pageData.article.dateModified,
            "publisher": {
                "@type": "Organization",
                "name": pageData.organizationName,
                "logo": {
                    "@type": "ImageObject",
                    "url": `${pageData.baseUrl}${pageData.article.logo}`
                }
            },
            "mainEntityOfPage": pageData.article.url,
            "description": pageData.article.description,
            "image": `${pageData.baseUrl}${pageData.article.image}`
        };
        addStructuredData([websiteData, localBusinessData, breadcrumbData, organizationData, articleData]);
        // console.log(pageData.article.logo);
    });
    onUnmounted(() => {
        removeStructuredData();
    });
    window.scrollTo(0, 0);
    // import CrumbComponent from '@/components/CrumbComponent.vue';
    // import HomeTopBackgroundComponent from "../components/home/HomeTopBackgroundComponent.vue";
    // import HomeHeaderComponent from "../components/home/HomeHeaderComponent.vue";
    // import CoeCategoryListComponent from "@/components/coe/CoeCategoryListComponent.vue";
    // const crumbdata = [
    //     {
    //         title: 'Home',
    //         type: 'link',
    //         to: '/'
    //     }
    // ];
</script>
<style scoped>
    /*  */
</style>