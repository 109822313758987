export const site = {
    title: 'Melinda Hospital - Jaringan Rumah Sakit Terbaik di Kota Bandung',
    description: 'Melinda Hospital adalah jaringan rumah sakit terbaik di Kota Bandung, yang telah melayani masyarakat dengan dedikasi tinggi selama lebih dari dua dekade.',
    lastUpdate: '20240827'
};
export const cartCookieVersion = 1; // change this value if you want to change the cart cookie structure !
export const sessionExpiration = 30; // it is used to manage user sessions, stored in browser cookies, declared in days
export const maxAvailableService = 86400000 * 30; // milliseconds * days




