<template>
    <section>
        <div class="section-header">
            <!-- <h2>Semua Artikel</h2> -->
        </div>
        <div class="section-body">
            <template v-if="articles">
                <FilteredArticlesComponent v-if="filteredArticles"/>
                <template v-else>
                    <template v-if="!searchbarCategory.input.value">
                        <div class="sb-inner" v-if="articles">
                            <div class="sb-card" v-for="article in articles" :key="article">
                                <router-link :to="'/articles/detail/' + article.slug">
                                    <div class="sbc-content">
                                        <div class="sbc-image">
                                            <img :src="article.thumb_small" alt="melinda care" v-if="article.thumb_small">
                                        </div>
                                        <div class="sbc-detail">
                                            <h3>{{ article.name }}</h3>
                                            <div class="sd-specialist">
                                                <span>{{ article.category?.name }}</span>
                                            </div>
                                            <!-- <div class="nlabel">
                                                <span>{{ article.category.name ? article.category.name : '' }}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="page-more">
                            <div class="next-loading" v-if="nextLoading">
                                <div class="loader-next"></div>
                            </div>
                            <div class="page-next" v-if="metaPage?.to < metaPage?.total">
                                <span @click="nextPage">Selanjutnya...</span>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="search-not-found">
                            <p>Tidak ditemukan artikel dengan kata kunci :</p>
                            <div v-if="searchbarCategory.input.value">"{{ searchbarCategory.input.value }}"</div>
                            <div v-if="searchbarSelectCategory.input.value.length > 0">"{{ searchbarSelectCategory.input.value }}"</div>
                            <div v-if="searchbarCategory.select.value != 'all'">dalam {{ searchbarCategory.select.title }}</div>
                            <div v-else>dalam semua kategori</div>
                        </div>
                    </template>
                </template>
            </template>
            <MainCardSkeleton v-else />
        </div>
    </section>
</template>
<script setup>
    import { computed } from "@vue/runtime-core";
    import { useArticleStore } from "../../stores/article";
    import MainCardSkeleton from "../skeletons/MainCardSkeleton.vue";
    import FilteredArticlesComponent from "@/components/article/FilteredArticlesComponent.vue";
    import { nextPage } from "@/functions/article";
    const articles = computed(() => useArticleStore().filteredArticles);
    const filteredArticles = computed(() => useArticleStore().filteredArticles);
    const searchbarSelectCategory = computed(() => useArticleStore().searchbarSelectCategory);
    const searchbarCategory = computed(() => useArticleStore().searchbarCategory);
    const metaPage = computed(() => useArticleStore().metaPage);
    const nextLoading = computed(() => useArticleStore().nextLoading);
    useArticleStore().setArticles();
</script>
<style scoped>
    section{
        padding-left: 9%;
        padding-right: 9%;
        padding-bottom: 2.5rem;
        width: 100%;
        transition: 0.2s;
    }
    .section-header{
        padding: 1%;
        padding-top: 0;
    }
    .section-header h2{
        font-family: poppins;
        color: var(--white);
        margin: 0;
    }
    .section-body{
        width: 100%;
    }
    .sb-inner{
        display: flex;
        flex-wrap: wrap;
    }
    .sb-card{
        position: relative;
        min-width: 290px;
        width: 25%;
        padding: 1%;
        transition: 0.2s;
        margin-bottom: 1rem;
        user-select: none;
    }
    .sb-card a{
        text-decoration: none;
        color: #575757;
    }
    .sbc-content{
        border-radius: 10px;
        overflow: hidden;
        /* height: 370px; */
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background: var(--white);
        transition: 0.2s;
    }
    .sbc-image{
        width: 100%;
        /* min-height: 150px; */
        height: 200px;
        overflow: hidden;
        /* background: lightgrey; */
        /* border: 1px solid green; */
    }
    .sbc-image img{
        width: 100%;
    }
    .sbc-detail{
        padding: 2.5%;
    }
    .sbc-detail h3{
        font-family: poppins;
        text-align: left;
        color: #575757;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    .sd-specialist{
        /* background: var(--primary); */
        color: var(--navy);
        padding: 0.5rem;
        font-family: poppins;
        font-size: 0.9rem;
        min-height: 75px;
    }
    /* .nlabel{
        margin-bottom: 1rem;
    }
    .nlabel span{
        color: var(--primary);
    } */
    .sbc-detail p{
        font-family: varela-round;
        font-weight: 500;
        font-size: 1rem;
        text-align: justify;
        color: #575757;
        margin: 0;
    }
    .search-not-found{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        text-align: center;
        color: var(--grey);
        word-break: break-all;
    }
    .page-more{
        padding: 0.5rem 1rem 0.5rem 1rem;
        display: flex;
        justify-content: center;
        color: var(--navy);
        font-style: italic;
        /* cursor: pointer; */
        user-select: none;
        transition: 0.2s;
    }
    .page-more:hover{
        color: var(--navy-hover);
    }
    .page-next span{
        cursor: pointer;
    }
    .next-loading{
        padding: 2.5rem 0 0 0;
    }
    @media only screen and (max-width: 1500px){
        section{
            padding-left: 4%;
            padding-right: 4%;
        }
    }
    @media only screen and (max-width: 1275px){
        .sb-card{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 950px){
        .sb-card{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        section{
            padding-left: 1%;
            padding-right: 1%;
        }
        .section-header h2{
            color: var(--grey);
        }
    }
    @media only screen and (max-width: 650px){
        .sb-card{
            min-width: 250px;
        }
    }
    @media only screen and (max-width: 550px){
        .sb-card{
            width: 100%;
        }
        /* .sbc-content{
            height: 500px;
        } */
    }
    /* @media only screen and (max-width: 350px){
        .sbc-content{
            height: 370px;
        }
    } */
</style>