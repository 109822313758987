<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <ContactHeader />
        <ContactForm />
        <!-- <ContactMaps /> -->
    </div>
</template>

<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import ContactHeader from '@/components/contact-us/ContactHeader.vue';
    import ContactForm from '@/components/contact-us/ContactForm.vue';
    // import ContactMaps from '@/components/contact-us/ContactMaps.vue';
    import { addStructuredData, setPageData } from '@/functions/general';
    import { onMounted } from 'vue';
    onMounted(() => {
        const pageData = {
            breadcrumb: [
                {
                    name: "Home",
                    url: process.env.VUE_APP_BASE_URL
                },
                {
                    name: "Hubungi Kami",
                    url: process.env.VUE_APP_BASE_URL + '/contact-us'
                },
            ]
        };
        setPageData({
            title: 'Melinda Hospital - Contact Us',
            description: 'Kami sangat menghargai setiap feedback dari pengguna kami. Gunakanlah menu Contact Us untuk berbagi pengalaman, pertanyaan, atau memberikan masukan dan saran kepada kami Melinda Hospital.',
            linkTag: {
                rel: 'canonical',
                href: 'https://melindahospital.com/contact-us'
            },
            ogData: {
                title: 'Melinda Hospital - Contact Us',
                type: 'website',
                url: 'https://melindahospital.com/contact-us',
                image: 'https://backend1.melindadev.com/images/art_cover/63045ffd12602.jpg'
            }
        });
        const breadcrumbData = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": pageData.breadcrumb.map((item, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": item.url
            }))
        };
        addStructuredData([breadcrumbData]);
    });
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Contact Us',
            type: 'text'
        }
    ];
    // document.title = 'Contact Us - Melinda Care';
    window.scrollTo(0, 0);
</script>
