<template>
    <div class="content">
        <TopBackgroundComponent/>
        <CrumbComponent :data="crumbdata"/>
        <ArticleDetailComponent/>
    </div>
</template>
<script setup>
    import TopBackgroundComponent from "../components/TopBackgroundComponent.vue";
    import CrumbComponent from '@/components/CrumbComponent.vue';
    import ArticleDetailComponent from "../components/article/ArticleDetailComponent.vue";
    import { onUnmounted } from "vue";
    import { removeStructuredData } from "@/functions/general";
    const crumbdata = [
        {
            title: 'Home',
            type: 'link',
            to: '/'
        },
        {
            title: 'Artikel',
            type: 'link',
            to: '/articles'
        },
        {
            title: 'Artikel Detail',
            type: 'text'
        }
    ];
    onUnmounted(() => {
        removeStructuredData();
    });
    window.scrollTo(0, 0);
</script>
<style scoped>
    /*  */
</style>



